import { useState, useEffect, useCallback, useRef } from "react";

// Inspired by => https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L239

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useClickOutside(onClick: () => void) {
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (onClick && ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    },
    [onClick],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return ref;
}
