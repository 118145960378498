import { useQuery } from "@tanstack/react-query";
import { sortBy, map } from "lodash";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

import { graphql } from "@/lib/gql";
import { SummaryHistoryQuery } from "@/lib/gql/graphql";
import { client } from "@/lib/graphql";
import { useSummaryId } from "../";

import s from "./style.module.css";

type SummaryList = SummaryHistoryQuery["actualUser"]["summaries"];

function useSummaryHistory(customerId?: string) {
  return useQuery({
    queryKey: ["summaryHistory", customerId],
    queryFn: async () => {
      const { actualUser } = await client.request(
        graphql(`
          query SummaryHistory($customerId: ID) {
            actualUser {
              summaries(customerId: $customerId) {
                id
                title
                summary
                createdAt
              }
            }
          }
        `),
        { customerId },
      );
      return actualUser?.summaries;
    },
    useErrorBoundary: true,
  });
}

function groupSummaries(summaries: SummaryList) {
  const today = [dayjs().startOf("day"), dayjs().endOf("day")];
  return [
    { name: "Today", range: today },
    {
      name: "Yesterday",
      range: [today[0].subtract(1, "day"), today[0]],
    },
    {
      name: "Previous 7 Days",
      range: [today[0].subtract(7, "day"), today[0].subtract(1, "day")],
    },
    {
      name: "Previous 30 Days",
      range: [today[0].subtract(30, "day"), today[0].subtract(7, "day")],
    },
    {
      name: "Older",
      range: [dayjs.unix(0), today[0].subtract(30, "day")],
    },
  ].map((g) => ({
    ...g,
    // Add summaries array to each group containing the summaries
    // falling in that date range. It's also important these are sorted
    // because it will determine the order in the UI.
    summaries: sortBy(
      summaries.filter((sm) =>
        dayjs(sm.createdAt).isBetween(g.range[0], g.range[1], "second", "[)"),
      ),
      "createdAt",
    ).reverse(),
  }));
}

function SummaryHistory() {
  const [summaryId, setSummaryId] = useSummaryId();
  const { data: summaryData, isLoading } = useSummaryHistory();
  const groups = groupSummaries(summaryData || []);

  const hasSummaries = groups.some((g) => g.summaries.length > 0);

  return hasSummaries ? (
    <ul className={s.history}>
      {map(
        groups,
        ({ name, summaries }) =>
          summaries.length > 0 && (
            <HistoryGroup
              key={name}
              name={name}
              summaries={summaries}
              activeSummaryId={summaryId}
              onClickEntry={setSummaryId}
            />
          ),
      )}
    </ul>
  ) : isLoading ? (
    <span className={s.loading} />
  ) : (
    <span className={s.empty}>
      <span>
        No summaries have been generated yet. Your summary history will appear
        here.
      </span>
    </span>
  );
}

interface HistoryGroupProps {
  summaries: SummaryList;
  activeSummaryId?: string;
  name: string;
  onClickEntry: (id: string) => void;
}

function HistoryGroup({
  summaries,
  activeSummaryId = "7",
  name,
  onClickEntry,
}: HistoryGroupProps) {
  return (
    <li className={s.historyGroup}>
      <span className={s.groupTitle}>{name}</span>
      <ul className={s.summaryList}>
        {summaries?.map((summary) => (
          <li
            key={summary.id}
            className={summary.id === activeSummaryId ? s.currentSummary : null}
            onClick={() => onClickEntry(summary.id)}
          >
            {summary.title}
          </li>
        ))}
      </ul>
    </li>
  );
}

export default SummaryHistory;
