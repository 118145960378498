import { EntitySelector } from "@/lib/gql/graphql";
import Pill from "@/atoms/Pill";

import { useSubjectString, buildSubjectString } from "../";

interface TeamFilterPillProps {
  entities?: EntitySelector[];
}

function TeamFilterPill({ entities = null }: TeamFilterPillProps) {
  const currentSubject = useSubjectString();
  const passedSubject = entities && buildSubjectString(entities);
  return <Pill>{passedSubject ?? currentSubject}</Pill>;
}

export default TeamFilterPill;
