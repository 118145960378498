import { useState, useEffect } from "react";
import cn from "classnames";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil } from "lodash";
import Markdown from "react-markdown";
import { useQueryState, queryTypes, HistoryOptions } from "next-usequerystate";
import { useUser } from "@auth0/nextjs-auth0/client";
import dayjs, { type Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

import { graphql } from "@/lib/gql";
import {
  SummaryQuery,
  EntitySelector,
  Tool,
  Sentiment,
} from "@/lib/gql/graphql";
import { client } from "@/lib/graphql";
import { DateRangePill, useDatePicker } from "@/molecules/DatePicker";
import { TeamFilterPill, useTeamFilter } from "@/organisms/TeamFilter";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/molecules/Tooltip";
import DialogDrawer from "@/templates/DialogDrawer";
import Loader from "@/atoms/Loader";
import Pill from "@/atoms/Pill";
import Magic from "@/atoms/icons/magic.svg";
import Button, { type Props as ButtonProps } from "@/atoms/Button";
import SummaryHistory from "./SummaryHistory";

import s from "./style.module.css";

function useShowSummary() {
  return useQueryState("showSummary", {
    ...queryTypes.boolean.withDefault(false),
    history: "push",
  });
}

export function useSummaryId(history: HistoryOptions = "push") {
  return useQueryState("summaryId", {
    ...queryTypes.string.withDefault(null),
    history,
  });
}

function useCreateSummary() {
  return useMutation({
    mutationFn: async ({
      /* @ts-ignore */
      customerId,
      /* @ts-ignore */
      from,
      /* @ts-ignore */
      to,
      /* @ts-ignore */
      teamFilters,
      /* @ts-ignore */
      tools,
      /* @ts-ignore */
      ticketLabels,
      /* @ts-ignore */
      ticketSentiment,
    }) => {
      const { createSummary: res } = await client.request(
        graphql(`
          mutation CreateSummary(
            $customerId: ID
            $from: ISO8601Date!
            $to: ISO8601Date!
            $teamFilters: [TeamFilter!]
            $tools: [Tool!]
            $ticketLabels: [String!]
            $ticketSentiment: Sentiment
          ) {
            createSummary(
              customerId: $customerId
              from: $from
              to: $to
              teamFilters: $teamFilters
              tools: $tools
              ticketLabels: $ticketLabels
              ticketSentiment: $ticketSentiment
            ) {
              status
              summary {
                id
                status
                createdAt
                title
                summary
                customerId
                customerName
                teamFilters {
                  id
                  kind
                  fullName
                }
                from
                to
              }
              errors
            }
          }
        `),
        {
          customerId,
          from,
          to,
          teamFilters,
          tools,
          ticketLabels,
          ticketSentiment,
        },
      );
      return res?.summary;
    },
    useErrorBoundary: true,
  });
}

function useSummary(id: string) {
  return useQuery({
    queryKey: ["summary", id],
    queryFn: async () => {
      if (isNil(id)) {
        return null;
      }
      const { actualUser } = await client.request(
        graphql(`
          query Summary($id: ID!) {
            actualUser {
              summary(id: $id) {
                id
                status
                createdAt
                title
                summary
                customerId
                customerName
                teamFilters {
                  id
                  kind
                  fullName
                }
                from
                to
              }
            }
          }
        `),
        { id },
      );

      const summary = actualUser?.summary;
      if (summary?.status !== "done") {
        throw "Not done";
      }
      return summary;
    },
    retry: (failureCount, error) => {
      const maxPollCount = 60;
      if (error === "Not done" && failureCount <= maxPollCount) {
        return true;
      }
      return false;
    },
    retryDelay: 2000,
    useErrorBoundary: true,
  });
}

function Summaries() {
  const { user } = useUser();
  const [displaySummary, setShowSummary] = useShowSummary();
  const [summaryId, setSummaryId] = useSummaryId();
  const { data: summary, isLoading: loading } = useSummary(summaryId);

  const aiEnabled = user?.company_ai_enabled;

  const handleClose = () => {
    setShowSummary(null);
    setSummaryId(null);
  };

  return displaySummary ? (
    <DialogDrawer
      title={<span>Summaries</span>}
      width={865}
      onClose={handleClose}
    >
      {!aiEnabled ? (
        <div className={s.optedOut}>
          You have opted out of AI features. To opt back in, please reach out to{" "}
          <a href="mailto:support@lumopath.ai">support@lumopath.ai</a>
        </div>
      ) : (
        <div className={s.layout}>
          <SummaryHistory />
          {isNil(summaryId) || loading ? (
            <div className={s.loading}>
              <div className={s.summaryLoader}>
                <Loader width={24} height={24} />
                <LoadingText />
              </div>
            </div>
          ) : (
            <Summary summary={summary} />
          )}
        </div>
      )}
    </DialogDrawer>
  ) : null;
}

interface SummaryProps {
  summary: SummaryQuery["actualUser"]["summary"];
}

function Summary({ summary }: SummaryProps) {
  const {
    from,
    to,
    teamFilters,
    customerName,
    title,
    summary: summaryBody,
  } = summary;
  return (
    <div className={s.summary}>
      <div className={s.filters}>
        <TeamFilterPill entities={teamFilters} />
        <DateRangePill dateRange={[from, to]} />
        <Pill>{customerName}</Pill>
      </div>
      <div className={s.title}>{title}</div>
      <div className={s.body}>
        <Markdown>{summaryBody}</Markdown>
      </div>
    </div>
  );
}

type AiSummaryButtonProps = ButtonProps & {
  customerId?: string;
  teamFilters?: EntitySelector[];
  dateRange?: Dayjs[];
  tools?: Tool[];
  ticketLabels?: string[];
  ticketSentiment?: Sentiment;
};

const LONG_LOAD_THRESHOLD_MS = 15000;

function LoadingText() {
  const [longLoad, setLongLoad] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (!timeoutId && !longLoad) {
      setTimeoutId(setTimeout(() => setLongLoad(true), LONG_LOAD_THRESHOLD_MS));
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [timeoutId, longLoad]);

  return !longLoad ? (
    <span>Generating Summary…</span>
  ) : (
    <span>Just a little bit longer…</span>
  );
}

export function AiSummaryButton({
  customerId = null,
  teamFilters,
  dateRange,
  tools = [],
  ticketLabels = [],
  ticketSentiment = null,
  style = "text",
  size = "smallest",
  disabled = false,
  className = null,
  type = "button",
  children = null,
  loading = false,
}: AiSummaryButtonProps) {
  const queryClient = useQueryClient();
  const [urlDateRange] = useDatePicker();
  const [urlTeamFilters] = useTeamFilter();
  const [_, setShowSummary] = useShowSummary();
  const [__, setSummaryId] = useSummaryId("replace");
  const createSummary = useCreateSummary();

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          data-feature="ai-summary-button"
          className={cn(s.aiButton, className)}
          type={type}
          size={size}
          style={style}
          disabled={disabled}
          loading={loading}
          onClick={(e) => {
            const [from, to] = dateRange || urlDateRange;
            e.stopPropagation();
            setSummaryId(null);
            setShowSummary(true);
            createSummary.mutate(
              /* @ts-ignore: Can't figure out this type error for the life of me */
              {
                customerId,
                from,
                to,
                teamFilters: teamFilters || urlTeamFilters,
                tools,
                ticketLabels,
                ticketSentiment,
              },
              {
                onSuccess: (summary) => {
                  queryClient.invalidateQueries({
                    queryKey: ["summaryHistory"],
                  });
                  setSummaryId(summary?.id);
                },
              },
            );
          }}
        >
          <Magic width={12} height={12} />
          {children}
        </Button>
      </TooltipTrigger>
      <TooltipContent>See summary of activities</TooltipContent>
    </Tooltip>
  );
}

export default Summaries;
