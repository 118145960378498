import classnames from "classnames";

import Person from "./person.svg";
import styles from "./style.module.css";

interface Props {
  className?: string;
}

function PersonIcon({ className, ...rest }: Props) {
  const classes = classnames(styles.root, className);
  return <Person className={classes} {...rest} />;
}

export default PersonIcon;
