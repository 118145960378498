import { useQueryState, queryTypes } from "next-usequerystate";
import { useQuery } from "@tanstack/react-query";

import { graphql } from "@/lib/gql";
import { client } from "@/lib/graphql";
import { EntitySelector } from "@/lib/gql/graphql";
export { default as TeamFilterPill } from "./TeamFilterPill";
export { default as TeamFilterList } from "./TeamFilterList";
export { default as TeamFilterSidebar } from "./TeamFilterSidebar";

const TEAM_FILTER_ENTITIES_QUERY = graphql(`
  query TeamFilter($selectors: [EntitySelector!]!) {
    entities(selectors: $selectors) {
      kind
      id
      fullName
    }
  }
`);

export function useTeamFilter() {
  return useQueryState(`teamFilters`, {
    ...queryTypes.json<EntitySelector[]>().withDefault([]),
    history: "push",
  });
}

export function useSubjects() {
  const [selectors] = useTeamFilter();

  return useQuery({
    queryKey: ["team-filter-entities", selectors],
    queryFn: async () => {
      const { entities } = await client.request(TEAM_FILTER_ENTITIES_QUERY, {
        selectors,
      });
      return entities;
    },
    useErrorBoundary: true,
  });
}

export function usePendingSubjects(pendingSelectors) {
  return useQuery({
    queryKey: ["pending-team-filter-entities", pendingSelectors],
    queryFn: async () => {
      const { entities } = await client.request(TEAM_FILTER_ENTITIES_QUERY, {
        selectors: pendingSelectors,
      });
      return entities;
    },
    useErrorBoundary: true,
  });
}

export function buildSubjectString(entities) {
  if (entities?.length === 0) {
    return "Full Team";
  } else if (entities?.length === 1) {
    return entities[0].fullName;
  } else if (entities?.length > 1) {
    const otherCount = entities.length - 1;
    return `${entities[0].fullName} +${otherCount} other${
      otherCount > 1 ? "s" : ""
    }`;
  } else {
    return "";
  }
}

export function useSubjectString() {
  const { data: entities } = useSubjects();
  return buildSubjectString(entities);
}
