import classnames from "classnames";

import Team from "./team.svg";
import styles from "./style.module.css";

interface Props {
  className?: string;
  width?: number;
  height?: number;
}

function TeamIcon({ className, ...rest }: Props) {
  const other = {
    width: 32,
    height: 32,
    ...rest,
  };
  const classes = classnames(styles.root, className);
  return <Team className={classes} {...other} />;
}

export default TeamIcon;
