import classnames from "classnames";

import People from "./people.svg";
import styles from "./style.module.css";

interface Props {
  className?: string;
}

function PeopleIcon({ className, ...rest }: Props) {
  const classes = classnames(styles.root, className);
  return <People className={classes} {...rest} />;
}

export default PeopleIcon;
