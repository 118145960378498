import { type ReactNode } from "react";
import cn from "classnames";

import s from "./style.module.css";

interface Props {
  children?: ReactNode;
  className?: string;
}

function Pill({ children, className }: Props) {
  return <div className={cn(s.pill, className)}>{children}</div>;
}

export default Pill;
