import Icon from "./x.svg";

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler;
}

function XIcon({ ...rest }: Props) {
  return <Icon {...rest} />;
}

export default XIcon;
