import { useState, useRef, useEffect } from "react";
import { reject } from "lodash";

import Drawer from "@/molecules/Drawer";
import Toggle from "@/atoms/Toggle";
import Button from "@/atoms/Button";
import {
  Entity,
  EntityKind,
  EntitySelector,
  TeamMember,
} from "@/lib/gql/graphql";
import EmployeeSearch from "@/organisms/EmployeeSearch";
import XIcon from "@/atoms/XIcon";
import PeopleIcon from "@/atoms/PeopleIcon";
import PersonIcon from "@/atoms/PersonIcon";
import CustomTeamIcon from "@/atoms/icons/teams-star.svg";
import SquigglyArrow from "./squiggly-arrow.svg";
import { usePendingSubjects, useTeamFilter } from "../";

import styles from "./style.module.css";

interface Props {
  headerText?: string;
  ctaText?: string;
  promptText?: string;
  onClose: () => void;
  open: boolean;
  filterByRole?: boolean;
  excludeEntireTeamOption?: boolean;
  excludeTeamsFromSearch?: boolean;
  updateQueryState?: boolean;
  focusOnSearchRef?: boolean;
  savePendingSelectors?: (selectors: EntitySelector[]) => void;
  formContent?: any;
  preSelectedSelectors?: EntitySelector[];
}

function TeamFilterSidebar({
  headerText = "Filter Team",
  ctaText = "Apply",
  promptText = "Start searching for teams or employees to explore their activity.",
  onClose,
  open,
  filterByRole = true,
  excludeEntireTeamOption = false,
  excludeTeamsFromSearch = false,
  updateQueryState = true,
  focusOnSearchRef = true,
  savePendingSelectors,
  formContent,
  preSelectedSelectors,
}: Props) {
  const [selectors, setSelectors] = useTeamFilter();
  const [pendingSelectors, setPendingSelectors] = useState(
    preSelectedSelectors || selectors,
  );
  const searchRef = useRef<HTMLInputElement>();
  const entireTeamSelected = (pendingSelectors || []).length === 0;

  const { data } = usePendingSubjects(pendingSelectors);
  const pendingEntities = data || [];

  const handleToggleSelected = (entity: Entity) => {
    const entryIsEqual = (e: Entity) =>
      e.kind === entity.kind && e.id === entity.id;
    const alreadySelected = pendingSelectors.some(entryIsEqual);
    if (alreadySelected) {
      setPendingSelectors(reject(pendingSelectors, entryIsEqual));
    } else {
      setPendingSelectors([
        ...pendingSelectors,
        { id: entity.id, kind: entity.kind },
      ]);
    }
  };

  const handleApply = async () => {
    if (updateQueryState) {
      await setSelectors(pendingSelectors);
    } else if (savePendingSelectors) {
      savePendingSelectors(pendingSelectors);
    }

    onClose();
  };

  useEffect(() => {
    // Focus search box on mount
    if (focusOnSearchRef) {
      searchRef.current.focus();
    }
  }, [focusOnSearchRef]);

  return (
    <Drawer onClose={onClose} open={open}>
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.title}>{headerText}</div>
          {!excludeEntireTeamOption && (
            <div className={styles.toggleContainer}>
              <Toggle
                on={entireTeamSelected}
                className={styles.toggle}
                onChange={() => setPendingSelectors([])}
              />
              Entire Team
            </div>
          )}
        </div>

        {formContent}

        <EmployeeSearch
          isMultiSelect
          containerClassName={styles.searchContainer}
          onSelect={handleToggleSelected}
          selected={pendingSelectors}
          ref={searchRef}
          filterByRole={filterByRole}
          excludeTeamsFromSearch={excludeTeamsFromSearch}
        />

        <div style={{ padding: "5px 0" }} />

        {pendingSelectors.length > 0 ? (
          <SelectedEntities
            entities={pendingEntities}
            onRemove={handleToggleSelected}
          />
        ) : (
          <div className={styles.emptyTable}>
            <SquigglyArrow />
            <div>{promptText}</div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button style="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          style="primary"
          onClick={handleApply}
          className={styles.primaryButton}
        >
          {ctaText}
        </Button>
      </div>
    </Drawer>
  );
}

interface SelectedEntitiesProps {
  entities: Entity[];
  onRemove: (entity: Entity) => void;
}

function SelectedEntities({ entities, onRemove }: SelectedEntitiesProps) {
  function renderEntityIcon(entityKind) {
    switch (entityKind) {
      case EntityKind.Employee:
        return <PersonIcon />;
      case EntityKind.OrgUnit:
        return <PeopleIcon />;
      case EntityKind.Team:
        return (
          <CustomTeamIcon
            className={styles.customTeamIcon}
            width={16}
            height={16}
          />
        );
      default:
        return null;
    }
  }

  return (
    <ul className={styles.selectedEmployees}>
      {entities.map((e) => (
        <li key={`${e.kind}-${e.id}`}>
          <Button style="text" onClick={() => onRemove(e)}>
            <XIcon />
          </Button>
          {renderEntityIcon(e.kind)}
          <span>{e.fullName}</span>
        </li>
      ))}
    </ul>
  );
}

export default TeamFilterSidebar;
