import { useRef, useState, ReactElement } from "react";
import { isNil, isEmpty, camelCase } from "lodash";
import { useUser } from "@auth0/nextjs-auth0/client";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useQueryState, queryTypes } from "next-usequerystate";
import { DateRange, RangePosition } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  PickersShortcutsProps,
  PickersShortcutsItem,
} from "@mui/x-date-pickers-pro";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import {
  PickersLayoutProps,
  usePickerLayout,
  pickersLayoutClasses,
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";
import { DateView } from "@mui/x-date-pickers/models";
import { Box } from "@mui/material";
import Calendar from "@mui/icons-material/Event";
import { useToast } from "@/lib/contexts/toast";

import Pill from "@/atoms/Pill";
import ChevronRight from "@/templates/CommunicationAggregates/SortOptions/chevron-right.svg";
import styles from "./style.module.css";

const FORMAT_SHORT_MONTH_AND_DATE = "MMM DD";

dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);

function namedRanges(name: string, relativeDate: Dayjs) {
  // NOTE: We make the assumption that the relativeDate is at
  //       the end of a week.
  switch (name) {
    case "last_week":
      return {
        label: "Last week",
        start: relativeDate.startOf("week"),
        end: relativeDate,
      };
    case "last_four_weeks":
      return {
        label: "Last 4 weeks",
        start: relativeDate.subtract(3, "week").startOf("week"),
        end: relativeDate,
      };
    case "last_twelve_weeks":
      return {
        label: "Last 12 weeks",
        start: relativeDate.subtract(11, "week").startOf("week"),
        end: relativeDate,
      };
    case "last_month":
      return {
        label: "Last month",
        start: relativeDate
          .startOf("month")
          .subtract(1, "day")
          .startOf("month"),
        end: relativeDate.startOf("month").subtract(1, "day").endOf("month"),
      };
    case "last_three_months":
      return {
        label: "Last 3 months",
        start: relativeDate
          .startOf("month")
          .subtract(3, "month")
          .startOf("month"),
        end: relativeDate.startOf("month").subtract(1, "day").endOf("month"),
      };
    case "last_six_months":
      return {
        label: "Last 6 months",
        start: relativeDate
          .startOf("month")
          .subtract(6, "month")
          .startOf("month"),
        end: relativeDate.startOf("month").subtract(1, "day").endOf("month"),
      };
    case "last_twelve_months":
      return {
        label: "Last 12 months",
        start: relativeDate
          .startOf("month")
          .subtract(12, "month")
          .startOf("month"),
        end: relativeDate.startOf("month").subtract(1, "day").endOf("month"),
      };
    case "this_year":
      return {
        label: "This year",
        start: relativeDate
          .week(relativeDate.year() === relativeDate.week(1).year() ? 1 : 2)
          .startOf("week"),
        end: relativeDate,
      };
    default:
      return null;
  }
}

function formatRange({ start, end }: { start: Dayjs; end: Dayjs }) {
  return `(${start.format(FORMAT_SHORT_MONTH_AND_DATE)} - ${end.format(
    FORMAT_SHORT_MONTH_AND_DATE,
  )})`;
}

export type CustomPickersShortcutsItem = Omit<
  PickersShortcutsItem<DateRange<Dayjs>>,
  "label"
> & {
  key: string;
  label: ReactElement;
  customIsVisible?: boolean;
  onClick?: () => void;
};

function shortcutsItems({
  customIsVisible,
  setShowCustomCalendars,
  relativeDate,
}): CustomPickersShortcutsItem[] {
  const rangeItems = [
    "last_week",
    "last_four_weeks",
    "last_twelve_weeks",
    "last_month",
    "last_three_months",
    "last_six_months",
    "last_twelve_months",
    "this_year",
  ].map((name) => {
    const range = namedRanges(name, relativeDate);
    return {
      key: `pickersShortcutItem-${camelCase(name)}`,
      label: (
        <div className={styles.shortCutItem}>
          <div className={styles.shortCutItemLabel}>{range.label}</div>
          <div className={styles.shortCutItemDates}>{formatRange(range)}</div>
        </div>
      ),
      getValue: () => [range.start, range.end] as DateRange<Dayjs>,
    };
  });

  return [
    ...rangeItems,
    {
      key: "pickersShortcutItem-custom",
      label: (
        <div className={styles.shortCutItem}>
          {customIsVisible && (
            <>
              <div className={styles.shortCutItemLabel}>Custom</div>
              <div className={styles.shortCutItemDates}>
                <ChevronRight className={styles.icon} />
              </div>
            </>
          )}
        </div>
      ),
      getValue: () => [null, null],
      customIsVisible,
      onClick: () => setShowCustomCalendars(customIsVisible),
    },
  ];
}

type CustomPickersShortcutsProps = Omit<
  PickersShortcutsProps<DateRange<dayjs.Dayjs>>,
  "items"
> & {
  items: CustomPickersShortcutsItem[];
};

function CustomRangeShortcuts({
  items,
  onChange,
  isValid,
  sx,
}: CustomPickersShortcutsProps) {
  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });
    return {
      key: item.key,
      label: item.label,
      customIsVisible: item.customIsVisible,
      onClick: () => {
        item.onClick ? item.onClick() : onChange(newValue);
      },
    };
  });

  return (
    <Box
      sx={{
        gridRow: 2,
        gridColumn: 1,
        ...sx,
      }}
    >
      <List>
        {resolvedItems.map((item) => {
          return (
            <span key={item.key}>
              {item.customIsVisible && <span className={styles.separator} />}
              <ListItem>
                {item.customIsVisible !== false && (
                  <Chip
                    sx={{
                      backgroundColor: "none!important",
                    }}
                    key={item.key}
                    label={item.label}
                    onClick={item.onClick}
                  />
                )}
              </ListItem>
            </span>
          );
        })}
      </List>
    </Box>
  );
}

function CustomLayout(
  props: PickersLayoutProps<Dayjs | null, Dayjs, DateView> & {
    showCustomCalendars: boolean;
  },
) {
  const { shortcuts, toolbar, tabs, content, actionBar } =
    usePickerLayout(props);

  return (
    <PickersLayoutRoot ownerState={props}>
      {shortcuts}
      {toolbar}
      {actionBar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {tabs}
        {props.showCustomCalendars && content}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

interface DatePickerProps {
  disabled?: boolean;
}

function DatePicker({ disabled }: DatePickerProps) {
  const [dateRange, setDateRange, tmpDates, setTmpDates] = useDatePicker();

  const { setToast } = useToast();

  const { dataCurrentTo } = useDefaultDateRange();

  const isAcceptedRef = useRef(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [showCustomCalendars, setShowCustomCalendars] = useState(false);

  const [rangePosition, setRangePosition] = useState("start" as RangePosition);

  return (
    <DateRangePicker
      format="MM/DD/YYYY"
      disabled={disabled}
      rangePosition={rangePosition}
      closeOnSelect
      sx={{ width: "fit-content", minWidth: "225px", cursor: "pointer" }}
      onOpen={() => {
        isAcceptedRef.current = false;
        setRangePosition("start");
        setIsPickerOpen(true);
      }}
      onClose={() => {
        setIsPickerOpen(false);
      }}
      onChange={(value) => {
        if (rangePosition === "start") {
          setTmpDates([value[0], null]);
          setRangePosition("end");
        } else {
          setTmpDates([tmpDates[0], value[1]]);
        }
      }}
      onAccept={(value) => {
        if (!isNil(value[0]) && !isNil(value[1])) {
          setTmpDates(value);
          setDateRange(value);
          isAcceptedRef.current = true;

          if (value[1].diff(value[0], "month") > 6) {
            setToast(
              "info",
              "",
              "Longer date ranges may not show period-over-period changes due to limited historical data.",
              8000,
            );
          }
        }
      }}
      disableFuture
      disableHighlightToday
      value={[dateRange[0], dateRange[1]]}
      calendars={2}
      currentMonthCalendarPosition={1}
      localeText={{ start: "From", end: "Till" }}
      minDate={dataCurrentTo
        .startOf("year")
        .subtract(1, "month")
        .startOf("year")}
      maxDate={dataCurrentTo}
      slots={{
        field: SingleInputDateRangeField,
        // @ts-ignore - Provide CustomRangeShortcuts with extra props
        shortcuts: CustomRangeShortcuts,
        // @ts-ignore - Provide CustomLayout with extra props
        layout: CustomLayout,
      }}
      slotProps={{
        desktopPaper: {
          sx: {
            marginLeft: showCustomCalendars ? null : "19px",
            marginTop: "3px",
          },
        },
        fieldSeparator: { children: "-" },
        // @ts-ignore
        textField: {
          InputProps: {
            onKeyDown: (e) => e.preventDefault(),
            startAdornment: <Calendar />,
            fullWidth: true,
            placeholder: "From - Till",
            sx: {
              cursor: "pointer",
              color: "var(--content-active)",
              fontWeight: "500",
              lineHeight: "20px",
              borderRadius: "8px",
              border: isPickerOpen
                ? "1px solid var(--pink-100)"
                : "1px solid var(--pink-50)",
            },
          },
          fullWidth: true,
          size: "small",
        },
        shortcuts: {
          // @ts-ignore - Overriding PickersShortcutsItem type to accept JSX.Element as a label
          items: shortcutsItems({
            setShowCustomCalendars,
            customIsVisible: !showCustomCalendars,
            relativeDate: dataCurrentTo,
          }),
        },
        actionBar: { actions: [] },
        // @ts-ignore - Provide CustomLayout with extra props
        layout: { showCustomCalendars },
        day: {
          onClick: (e) => {
            // Handle selecting the currently selected start date as the next range's start date
            if (
              // @ts-ignore - `attributes` exists
              e.target.attributes["aria-selected"]?.value &&
              // @ts-ignore - `attributes` exists
              e.target.attributes["data-position"]?.value === "start"
            ) {
              setTmpDates([tmpDates[0], null]);
              setRangePosition("end");
            }
          },
        },
      }}
      shouldDisableDate={(date, position) => {
        // If a date range is already selected (eg, by a query param state or shortcut) then assume it is valid.
        if (isAcceptedRef.current) {
          return false;
        }

        if (position === "start") {
          if (date >= dataCurrentTo.startOf("week")) {
            // Disable dates that belong to this week.
            return true;
          } else if (
            date.date() === 1 &&
            date >= dataCurrentTo.startOf("month")
          ) {
            // Disable the first of this month.
            return true;
          }

          // Disable any date that is not the first of a month or a Monday.
          return date.day() !== 1 && date.date() !== 1;
        } else if (position === "end") {
          if (
            tmpDates[0] &&
            tmpDates[0].day() === 1 &&
            tmpDates[0].date() === 1
          ) {
            // If the start date is a Monday and the first of the month,
            // then disable dates on or before the start date and dates that are not Sundays or the end of a month.
            return (
              date <= tmpDates[0] ||
              (date.day() !== 0 && date.date() !== date.endOf("month").date())
            );
          } else if (tmpDates[0]?.day() === 1) {
            // If the start date is a Monday,
            // then disable dates on or before the start date and dates that are not Sundays.
            return date.day() !== 0 || date <= tmpDates[0];
          } else if (tmpDates[0]?.date() === 1) {
            // If the start date is the first of a month,
            // then disable dates on or before the start date and dates that are not the end of a month.
            return (
              date.date() !== date.endOf("month").date() || date <= tmpDates[0]
            );
          }

          // Disable dates that are not Sundays and on or before the selected start date.
          return date.day() !== 0 || date <= tmpDates[0];
        }

        return false;
      }}
    />
  );
}

export function useDefaultDateRange() {
  const { user } = useUser();
  const dataCurrentTo = user.company_data_current_to
    ? dayjs.utc(user.company_data_current_to as string)
    : // NOTE: If we don't have a current date for the data then
      // it is assumed that last week's data exists. This probably
      // isn't a safe assumption but it makes testing in development
      // much easier. Meaning you don't have to manually update your
      // current date every week. We can change this if it turns out
      // to be a problem.
      dayjs.utc().subtract(7, "day").endOf("week");
  const range = namedRanges("last_twelve_weeks", dataCurrentTo);
  return {
    dataCurrentTo,
    defaultRange: [range.start, range.end],
  };
}

export function toUtcDateStr(d: Dayjs) {
  return d.utc(true).toISOString().split("T")[0];
}

export function useDatePicker(): [
  Dayjs[],
  (range: Dayjs[]) => Promise<URLSearchParams>,
  Dayjs[],
  (range: Dayjs[]) => void,
] {
  const { setToast } = useToast();
  const { defaultRange, dataCurrentTo } = useDefaultDateRange();
  const [dateRangeStr, setDateRangeStr] = useQueryState(
    "dateRange",
    queryTypes
      .array(queryTypes.string)
      .withDefault(defaultRange.map((d) => d.format("YYYY-MM-DD"))),
  );
  const [tmpDates, setTmpDates] = useState(dateRangeStr.map(dayjs));

  const dateRange = dateRangeStr ? dateRangeStr.map((s) => dayjs.utc(s)) : [];
  // The dateRange url state requires dates to be strings
  const setDateRange = (range: Dayjs[]) =>
    setDateRangeStr(range.map((d) => d.format("YYYY-MM-DD")));

  const dateRangeIsValid =
    !isEmpty(dateRange) &&
    dateRange.length === 2 &&
    !isEmpty(dateRange[0]) &&
    !isEmpty(dateRange[1]) &&
    dateRange[0].isValid() &&
    dateRange[1].isValid() &&
    dateRange[0].isSameOrBefore(dataCurrentTo) &&
    dateRange[1].isSameOrBefore(dataCurrentTo);

  if (!dateRangeIsValid) {
    setToast("info", "", "Invalid date range, resetting to default", 8000);
    setDateRange(defaultRange);
  }

  return [
    dateRangeIsValid ? dateRange : defaultRange,
    setDateRange,
    tmpDates,
    setTmpDates,
  ];
}

interface DateRangePill {
  dateRange?: [Dayjs, Dayjs];
}

export function DateRangePill({ dateRange = null }: DateRangePill) {
  const [currentDateRange] = useDatePicker();
  const [from, to] = dateRange || currentDateRange;
  const format = "MM-DD-YYYY";
  return (
    <Pill>
      {dayjs.utc(from).format(format)} <Caret /> {dayjs.utc(to).format(format)}
    </Pill>
  );
}

function Caret() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 11.1968V2.80381L10.0459 7.00031L5.25 11.1968ZM5.82663 11.8557L10.6216 7.65919C10.7156 7.57706 10.7909 7.47578 10.8425 7.36214C10.8942 7.24849 10.9209 7.12513 10.9209 7.00031C10.9209 6.8755 10.8942 6.75214 10.8425 6.63849C10.7909 6.52485 10.7156 6.42357 10.6216 6.34144L5.8275 2.14494C5.25963 1.64969 4.375 2.05219 4.375 2.80381V11.1968C4.37486 11.365 4.4232 11.5297 4.51425 11.6712C4.60529 11.8126 4.73518 11.9248 4.88836 11.9943C5.04153 12.0639 5.2115 12.0878 5.37791 12.0632C5.54431 12.0386 5.7001 11.9665 5.82663 11.8557Z"
        fill="#9E9EA9"
      />
    </svg>
  );
}

export default DatePicker;
