import {
  useState,
  useRef,
  createContext,
  useContext,
  forwardRef,
  isValidElement,
  useMemo,
  cloneElement,
  type ReactNode,
  type CSSProperties,
  type MouseEventHandler,
} from "react";
import { mergeRefs } from "react-merge-refs";
import {
  useFloating,
  arrow,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  type Placement,
} from "@floating-ui/react-dom-interactions";
import cn from "classnames";

export { type Placement } from "@floating-ui/react-dom-interactions";

import styles from "./style.module.css";

interface UseTooltipProps {
  initialOpen?: boolean;
  placement?: Placement; // TODO: Be more specific here
  open?: boolean;
  onOpenChange?: () => void;
}

export function useTooltip({
  initialOpen = false,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: UseTooltipProps) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const arrowRef = useRef(null);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [arrow({ element: arrowRef }), offset(5), flip(), shift()],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data, arrowRef],
  );
}

const TooltipContext = createContext(null);

export const useTooltipState = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

type TooltipProps = {
  children: ReactNode;
  className?: string;
} & UseTooltipProps;

export function Tooltip({ children, ...options }: TooltipProps) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

type TooltipTriggerProps = {
  children: ReactNode;
  asChild?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
};

export const TooltipTrigger = forwardRef(
  ({ children, asChild = false, ...props }: TooltipTriggerProps, propRef) => {
    const state = useTooltipState();

    // @ts-ignore
    const childrenRef = children.ref;
    const ref = useMemo(
      () => mergeRefs([state.reference, propRef, childrenRef]),
      [state.reference, propRef, childrenRef],
    );

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        state.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": state.open ? "open" : "closed",
        }),
      );
    }

    return (
      <span
        ref={ref}
        // The user can style the trigger based on the state
        data-state={state.open ? "open" : "closed"}
        {...state.getReferenceProps(props)}
      >
        {children}
      </span>
    );
  },
);
TooltipTrigger.displayName = "TooltipTrigger";

type TooltipContentProps = {
  children: ReactNode;
  asChild?: boolean;
  style?: CSSProperties;
  className?: string;
};

export const TooltipContent = forwardRef(
  ({ children, className, ...props }: TooltipContentProps, propRef) => {
    const state = useTooltipState();
    const arrow = state?.middlewareData?.arrow;
    const ref = useMemo(
      () => mergeRefs([state.floating, propRef]),
      [state.floating, propRef],
    );

    let arrowPosition: {
      left?: number;
      right?: number;
      top?: number;
      bottom?: number;
    };
    switch (state.placement) {
      case "top":
        arrowPosition = { left: arrow?.x || -4, bottom: arrow?.y || -4 };
        break;
      case "bottom":
        arrowPosition = { left: arrow?.x || -4, top: arrow?.y || -4 };
        break;
      case "left":
        arrowPosition = { right: arrow?.x || -4, top: arrow?.y || -4 };
        break;
      case "right":
        arrowPosition = { left: arrow?.x || -4, top: arrow?.y || -4 };
        break;
      default:
        arrowPosition = { left: arrow?.x || -4, top: arrow?.y || -4 };
    }

    return (
      <FloatingPortal>
        {state.open && (
          <div
            ref={ref}
            className={cn(styles.tooltipContent, className)}
            style={{
              position: state.strategy,
              top: state.y ?? 0,
              left: state.x ?? 0,
              visibility: state.x == null ? "hidden" : "visible",
              ...props.style,
            }}
            {...state.getFloatingProps(props)}
          >
            <span
              className={styles.arrow}
              ref={state.arrowRef}
              style={arrowPosition}
            />
            {children}
          </div>
        )}
      </FloatingPortal>
    );
  },
);
TooltipContent.displayName = "TooltipContent";
