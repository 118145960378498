import {
  useState,
  useEffect,
  forwardRef,
  type ForwardedRef,
  type KeyboardEvent,
} from "react";
import { isNil, isEmpty, some } from "lodash";
import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";

import { client } from "@/lib/graphql";
import { graphql } from "@/lib/gql";
import { useDebounce } from "@/lib/hooks";
import { Entity, EntityKind } from "@/lib/gql/graphql";
import Popup from "@/atoms/Popup";
import PersonIcon from "@/atoms/PersonIcon";
import PeopleIcon from "@/atoms/PeopleIcon";
import SearchInput from "@/molecules/SearchInput";

import styles from "./style.module.css";
import CustomTeamIcon from "@/atoms/icons/teams-star.svg";
import EmployeeIcon from "@/atoms/EmployeeIcon";
import TeamIcon from "@/atoms/TeamIcon";

interface Props {
  containerClassName?: string;
  isMultiSelect?: boolean;
  onSelect: (selected: Entity) => void;
  selected: Entity[];
  filterByRole?: boolean;
  excludeTeamsFromSearch?: boolean;
}

const QUERY = graphql(`
  query SearchTeamsAndEmployees(
    $query: String!
    $filterByRole: Boolean
    $excludeTeamsFromSearch: Boolean
  ) {
    searchTeamsAndEmployees(
      query: $query
      filterByRole: $filterByRole
      excludeTeamsFromSearch: $excludeTeamsFromSearch
    ) {
      id
      kind
      managerId
      fullName
    }
  }
`);

const EmployeeSearch = forwardRef(
  (
    {
      containerClassName,
      isMultiSelect,
      onSelect,
      selected,
      filterByRole = true,
      excludeTeamsFromSearch = false,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [search, setSearch] = useState("");
    const [focus, setFocus] = useState(0);

    // Data fetching state
    const debouncedSearch = useDebounce(search, 250);

    /////////////////////////////////
    // Data loading
    /////////////////////////////////
    const { data } = useQuery({
      queryKey: [
        "searchTeamsAndEmployees",
        debouncedSearch,
        filterByRole,
        excludeTeamsFromSearch,
      ],
      queryFn: async () => {
        const { searchTeamsAndEmployees } = await client.request(QUERY, {
          query: debouncedSearch,
          filterByRole,
          excludeTeamsFromSearch,
        });
        return searchTeamsAndEmployees;
      },
      enabled: !!search,
      useErrorBoundary: true,
    });
    const searchResults = isEmpty(debouncedSearch) ? null : data;

    useEffect(() => {
      if (searchResults) {
        // When the results array changes we need to clamp the focus
        // between 0 and the number of results so that we always have
        // a valid focus.
        setFocus(Math.max(0, Math.min(focus, searchResults.length - 1)));
      }
    }, [searchResults]);

    function handleSelect(item: Entity) {
      if (!selected.includes(item)) {
        onSelect(item);
      }

      if (!isMultiSelect) {
        setSearch("");
      }
    }

    function handleMouseEnter(idx: number) {
      setFocus(idx);
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          if (searchResults) {
            setFocus(Math.min(focus + 1, searchResults.length - 1));
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          if (searchResults) {
            setFocus(Math.max(0, focus - 1));
          }
          break;
        case "Enter":
          e.preventDefault();
          if (searchResults && searchResults[focus]) {
            handleSelect(searchResults[focus]);
          }
          break;
        case "Escape":
          e.preventDefault();
          (e.target as HTMLElement).blur(); // Gross
          break;
      }
    }

    function handleClear() {
      setSearch("");
    }

    function renderEntityIcon(entry) {
      switch(entry.kind) {
        case EntityKind.Employee:
          return <>
            <PersonIcon />
            <span>{entry.fullName}</span>
          </>
        case EntityKind.OrgUnit:
          return <>
            <PeopleIcon />
            <span>{entry.fullName}&apos;s Team</span>
          </>
        case EntityKind.Team:
          return <>
            <CustomTeamIcon className={styles.customTeamIcon} width={16} height={16} />
            <span>{entry.fullName}</span>
          </>
        default:
          return null;
      }
    }

    return (
      <div className={classnames(styles.searchContainer, containerClassName)}>
        <SearchInput
          value={search}
          className={styles.search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          onKeyDown={handleKeyDown}
          onClear={handleClear}
          placeholder="Search"
          ref={ref}
        />
        {!isNil(searchResults) && (
          <Popup className={styles.searchPopup} open={true}>
            <ul className={styles.searchResults}>
              {searchResults.length > 0 ? (
                searchResults.map((entry: Entity, idx: number) => {
                  const checked = some(selected, {
                    id: entry.id,
                    kind: entry.kind,
                  });
                  const focused = idx === focus;
                  const classes = classnames({
                    [styles.searchResultSelected]: checked,
                    [styles.searchResultFocused]: focused,
                  });
                  return (
                    <li
                      key={`${entry.kind}-${entry.id}`}
                      className={classes}
                      onClick={() => handleSelect(entry)}
                      onMouseEnter={() => handleMouseEnter(idx)}
                    >
                      { renderEntityIcon(entry) }
                    </li>
                  );
                })
              ) : (
                <li className={styles.noResults}>No Results</li>
              )}
            </ul>
          </Popup>
        )}
      </div>
    );
  },
);
EmployeeSearch.displayName = "EmployeeSearch";

export default EmployeeSearch;
