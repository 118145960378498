import { useCallback, useRef, useEffect, type ReactNode } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";

import styles from "./style.module.css";

interface Props {
  children?: ReactNode;
  open: boolean;
  width?: number | string;
  onClose: () => void;
  className?: string;
}

function Drawer({ children, open, width = 589, onClose, className }: Props) {
  const backgroundRef = useRef(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const backgroundEl = backgroundRef.current;
      const targetEl = event.target instanceof Node && event.target;
      if (
        backgroundEl &&
        (backgroundEl === targetEl || backgroundEl.contains(targetEl))
      ) {
        handleClose();
      }
    },
    [handleClose],
  );

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, true);
    return () => {
      document.removeEventListener("keydown", handleKeydown, true);
    };
  }, [handleKeydown]);

  return open
    ? createPortal(
        <>
          <div className={styles.root} style={{ width }}>
            <div
              className={classnames(styles.drawer, className)}
              style={{ width }}
            >
              {children}
            </div>
          </div>
          <div ref={backgroundRef} className={styles.background} />
        </>,
        document.body,
      )
    : null;
}

export default Drawer;
