import classnames from "classnames";

import styles from "./style.module.css";

function Toggle({
  on = false,
  disabled = false,
  loading = false,
  tabIndex = "0",
  className = null,
  onChange = () => {},
}) {
  function handleClick() {
    return disabled || loading ? null : onChange();
  }

  function handleKeyDown(e) {
    switch (e.key) {
      case "Enter":
      case " ":
        e.preventDefault();
        handleClick();
        break;
      case "Escape":
        e.preventDefault();
        e.target.blur();
        break;
    }
  }

  return (
    <span
      tabIndex={tabIndex}
      className={classnames(styles.root, className, {
        [styles.disabled]: disabled,
        [styles.loading]: loading,
      })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <span className={classnames(styles.body, { [styles.on]: on })}>
        <span className={styles.spacer} />
        <span className={styles.indicator} />
      </span>
    </span>
  );
}

export default Toggle;
