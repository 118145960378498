import { ReactNode } from "react";

import Drawer from "@/molecules/Drawer";
import XIcon from "@/atoms/XIcon";
import Button from "@/atoms/Button";

import s from "./style.module.css";

interface Props {
  closeButton?: boolean;
  title?: ReactNode;
  toolbar?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  width?: number | string;
  onClose?: () => void;
}

function DialogDrawer({
  closeButton = true,
  title,
  toolbar,
  children,
  footer,
  width,
  onClose,
}: Props) {
  return (
    <div>
      <Drawer width={width} open={true} onClose={onClose}>
        <div className={s.layout}>
          <div className={s.outerHeader}>
            <div className={s.header}>
              <span className={s.title}>{title}</span>
              {closeButton && (
                <Button onClick={onClose} size="small" style="text">
                  <XIcon />
                </Button>
              )}
            </div>
            {toolbar && <div>{toolbar}</div>}
          </div>
          {children && <div className={s.body}>{children}</div>}
          {footer && <div className={s.footer}>{footer}</div>}
        </div>
      </Drawer>
    </div>
  );
}

export default DialogDrawer;
