import classnames from "classnames";

import XIcon from "@/atoms/XIcon";
import styles from "./style.module.css";

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler;
}

function ClearIcon({ className, onClick, ...rest }: Props) {
  const classes = classnames(styles.root, className);
  return (
    <div tabIndex={0} className={classes} onClick={onClick} {...rest}>
      <XIcon />
    </div>
  );
}

export default ClearIcon;
