import { forwardRef, type ForwardedRef } from "react";
import styles from "./style.module.css";
import classnames from "classnames";

import ClearIcon from "@/atoms/ClearIcon";

interface Props {
  value?: string;
  placeholder?: string;
  onClick?: React.MouseEventHandler;
  onChange?: React.ChangeEventHandler;
  onClear?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  className?: string;
}

const SearchInput = forwardRef(
  (
    {
      value = "",
      placeholder = "Search",
      onClick,
      onChange,
      onClear,
      onKeyDown,
      className,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const classes = classnames(styles.root, className);
    return (
      <div className={classes}>
        <input
          ref={ref}
          className={styles.search}
          type="search"
          value={value}
          onClick={onClick}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
        />
        <ClearIcon className={styles.clear} onClick={onClear} />
      </div>
    );
  },
);
SearchInput.displayName = "SearchInput";

export default SearchInput;
